<!-- 事件确认页面  -->
<template>
  <div class="">
    <ax-table
      ref="tableBox"
      :columns="columns"
      :show-search="true"
      :searchForm="searchForm"
      @delect="del"
      :showToolBar="true"
      :toolActions="toolActions"
      @action-column-click="actionColumnClick"
      @pop-confirm="popConfirm"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :dataSourceApi="dataSourceApis"
      :dataSourceParams="dataSourceParams"
       :scroll="{ y: '55vh', x: '80vw' }"
    >
    </ax-table>
    <!-- 确定弹窗 -->
    <sure ref="sure"></sure>
    <!-- 详情弹窗 -->
     <watch ref="watch"></watch>
    <!-- <refuse ref="refuseInfo"></refuse> -->
  </div>
</template>

<script>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import <组件名称> from '<组件路径>';
// import pass from "./pass.vue";
const searchForm = [
  {
    label: "关键字",
    type: "input",
    model: "searchText",
    options: { placeholder: "请输入事件名称/编号..." },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "事件类别",
    type: "select",
    model: "eventCategory",
    options: { placeholder: "请选择", mode: "multiple" }, //多选
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "事件来源",
    type: "select",
    model: "eventSource",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
];

import api from "./api";
import sure from "./sure/sure.vue";
import watch from "./watch/watch.vue";
export default {
  components: { sure,watch },
  //import引入的组件需要注入到对象中才能使用
  data() {
    //这里存放数据
    return {
      api,
      options: [],
      activeTab: ["1"],
      visible: false,
      activeTabIndex: ["1"],
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "事件编号",
            dataIndex: "accountNum1",
            ellipsis: false,
            width: 60,
          },
          {
            title: "所属网格",
            dataIndex: "currentArea",
            ellipsis: false,
            width: 200,
          },
          {
            title: "事件名称",
            dataIndex: "cellName",
            ellipsis: true,
            width: 120,
          },
          {
            title: "事件状态",
            dataIndex: "accountNum",
            ellipsis: false,
            width: 80,
          },
          {
            title: "事件类别",
            dataIndex: "deleteFlag",
            ellipsis: false,
            width: 80,
          },
          {
            title: "事件来源",
            dataIndex: "politicalStatus_dictText",
            ellipsis: false,
            width: 100,
          },
          {
            title: "超时情况",
            dataIndex: "createBy1",
            ellipsis: false,
            width: 100,
          },
          {
            title: "上报人",
            dataIndex: "createBy",
            ellipsis: false,
            width: 100,
          },
          {
            title: "联系方式",
            dataIndex: "phone",
            ellipsis: false,
            width: 100,
          },
          {
            title: "登记时间",
            dataIndex: "updateTime",
            ellipsis: false,
            width: 150,
          },
          {
            title: "处理期限",
            dataIndex: "birthDate",
            ellipsis: false,
            width: 150,
          },
        ],
        false,
        {
          // fixed: "right",
          width: 180,
          // 操作部分
          actions: [
            {
              props: (record) => {
                return {
                  text: "确认",
                  name: "sure",
                  type: "#67C23A",
                  link: true,
                  popDisabled: true, //是否要弹窗,
                  // title: "确定恢复团员信息?",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "详情",
                  name: "watch",
                  type: "#E6A23C",
                  link: true,
                  popDisabled: true, //是否要弹窗,
                  // title: "确定恢复团员信息?",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "del",
                  type: "#F95A5A",
                  link: true,
                  popDisabled: false, //是否要弹窗,
                  // title: "确定恢复团员信息?",
                };
              },
            },
          ],
        }
      ),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 70,
      }),
      toolActions: [
        { name: "bulkDeletion", text: "批量删除", type: "#F95A5A" },
      ],
      selectedRowKeys: [],
      dataSourceApis: api.list,
      dataSourceParams: { type: "0" },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 表格操作
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 确认
        case "sure":
          this.$refs.sure.openModal({ record, title: "事件确认" });
          break;
        // 详情
        case "watch":
          this.$refs.watch.openModal({ record, title: "事件详情" });
          break;
      }
    },
    // 根据id恢复团员
    async popConfirm({ record }) {
      const res = await api.deleteResidentArchivesById(record.id);
      if (res.status === 200) {
        this.$message.success("删除成功");
        this.$refs.tableBox.getDataSource();
      }
    },
    refsh() {
      this.$refs.tableBox.getDataSource();
    },
    // 批量删除
    del() {
      var that = this;
      if (that.selectedRowKeys.length === 0) {
        this.$message.warning("请勾选要删除的数据！");
      } else {
        this.$confirm({
          title: "是否删除",
          content: "确认是否删除当前选中内容",
          cancelText: "取消",
          okText: "确定",
          onOk: function () {
            api
              .deleteResidentArchivesByIds(that.selectedRowKeys.join(","))
              .then((res) => {
                that.$message.success("删除成功");
                that.$refs.tableBox.getDataSource();
              });
          },
        });
      }
    },
    onSelectChange(selectedRowKeys, row) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 获取字典
    async getDictionsary() {
      // 事件类别
      await api.dictData({ dicKind: "event_category" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
          "eventCategory",
          {
            options: {
              options,
              allowClear: true,
              placeholder: "请选择",
              mode: "multiple",
            },
          }
        );
      });
      // 事件来源
      await api.dictData({ dicKind: "event_source" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
          "eventSource",
          { options: { options, allowClear: true, placeholder: "请选择" } }
        );
      });
    },
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {
    this.getDictionsary();
    // this.getRegionInfo();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
<style lang='less' scoped>
/deep/.ant-menu-item-selected {
  border-bottom: 2px solid #556bff !important;
  color: #556bff !important;
}

/deep/.ant-menu-item:hover {
  border-bottom: 2px solid #556bff !important;
  color: #556bff !important;
}
.grey {
  background-color: #e6e9ee;
  color: #2d3138;
  border: #e6e9ee;
  border-radius: 4px;
}
.green {
  background-color: #f6ffed;
  color: #389e0d;
  border-radius: 4px;
  border: #f6ffed;
}
/deep/ .ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-left: none;
}
</style>